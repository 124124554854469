import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey =
  'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAlFQQADldQVJxkLd67rws' +
  'DHfdKpzLEGm9dKD2YlajJf/mnhhNlF0jSDEPajs3rZ3R+J7QJOAT6FAD9Tbx2FcE' +
  'A3bXroDHqIiMUbCLO2MOZ01QHYkGJnqZoYgNeTOZy07movI6Z0iaH69Z06acQSyx' +
  'dFtDSNia9LK4iWe/G2ecVVqkmxgPHbs16oGUJmZVK4+cO0PUeQJbeI1XvHme6eV3' +
  '/UIliMrXKG0TYQmprS6u1xbmp2luUqJ1X3t1ytrS/EzTyaknVVuxTN119eLmB2tT' +
  '1jtEXto8WKYxe3XdwYKIzhM6xWQp1+3VJCd+qFLuSafdNkiUhN0JmcbxwukQ/xLp' +
  '2wIDAQAB'

const privateKey =
  'MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCUVBAAOV1BUnGQ' +
  't3ruvCwMd90qnMsQab10oPZiVqMl/+aeGE2UXSNIMQ9qOzetndH4ntAk4BPoUAP1' +
  'NvHYVwQDdteugMeoiIxRsIs7Yw5nTVAdiQYmepmhiA15M5nLTuai8jpnSJofr1nT' +
  'ppxBLLF0W0NI2Jr0sriJZ78bZ5xVWqSbGA8duzXqgZQmZlUrj5w7Q9R5Alt4jVe8' +
  'eZ7p5Xf9QiWIytcobRNhCamtLq7XFuanaW5SonVfe3XK2tL8TNPJqSdVW7FM3XX1' +
  '4uYHa1PWO0Re2jxYpjF7dd3BgojOEzrFZCnX7dUkJ36oUu5Jp902SJSE3QmZxvHC' +
  '6RD/EunbAgMBAAECggEAcO5DtHf4aWiEsIMRpLw96UxFZlE0Rg3LwgVfMWX3F1Jr' +
  'Wkj0oBX9a9R2gRIPBS+YkusPXFQCtChfqw4nOeieVKjh86l22fkEpJl+mDuF1t7F' +
  'NYeC2D8Vwydb4sCHwoQTE1LLZqGfPqp6AKvkYbbOcOGfK9JGwDFmUK9dkFblbxHI' +
  'aW3nDbf6Vc6/7aH1BRvvqWlQK4pffgh6pikuYD1fMC6o3qSIewsHqXDJKSObNhP1' +
  'YodgGCXaApQSLa4GzTsIITrXEePjkA3Ql829RJug7br5vyiKC35z0rN0BmhxBaUR' +
  '4IU+74F/ZlAHheDhplC1Gyr5ei+iak4ulA3fu3XoQQKBgQDFu22w7OLDXFjwBiPx' +
  '88aQUpCXCyrPEC6BQq9M8KF0IOPhPUONcPiZsH/cmlOMeYblxutd0Ov89mjdJ8xd' +
  'kTnf5sVxTK90iMaHH4KkYlMdzgUOIIsRbJ1LKCex3R5MuPdHODXRvan0UdvcXCqx' +
  'UM+BkccqNOrpijG3HZeZ3Ln1oQKBgQDACbLZ9mxtnuzN/U97iVYBRDLn8wyecIc4' +
  '3dO3duYzT4OtugdSF4WzPz4l0pd4cqIFBRmr429cH5ebhDcwEnmfr+rd3na9FPC2' +
  'R6wy4Jl1JYCvTbmi3UGUor2T+gpHOcGhUiH3hVpxZEC3iyyDrOfgM6Xim6BSAyyU' +
  '2vitRSz1+wKBgHyBAhavcjG1CkNIeoW1C8iAVI7m9zOeasn1ZVA7DE6FDzgaTVjY' +
  'U5DidABCJG2FcA1q7XULQizsMydeEe6wrRJwys4GjUwBe5BFpQtA5jkUKla04xw7' +
  'medjB2uVYajJptpvaGQC2ur+SEQ+vJsd2Ssq0Y+f9nLLE3bL6VN2iplBAoGAE7kr' +
  'SF2Z10xqT+HDZxG85pZlkBMTabZu/PUziHVKwVxaTt57YAAQeE93h5Twf6tuXoue' +
  'jlvmlRSdkphqvqI3IaKEwdLWaSccbexAkF0nBbQbWmXmOgb1dFmelPMqWRRigboY' +
  'yP5GQr0kNHWz1bZNfmZvlC2dsq6mxocS4WDodD8CgYEAi9o4u7V0YNT+pJEFPnm5' +
  'bxE6ClymhNXeeOlhG/8rzZQvtMwIHghtaa0YBpagwfmo/+8aEf503rxdy+BrAGQi' +
  '0AVSs6wIRCbnvId7sfyUDmqQGE6WFKURQ45JEBoDGAffcrer0gnLHcBLbQRzPz+x' +
  'Vo+O+n2MAxgdqNw2iao42hY='

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey) // 设置私钥
  return encryptor.decrypt(txt) // 对数据进行解密
}
