import request from '@/utils/request'

/**
 * 获取主题配置
 */
export function getThemeConfig() {
  return request({
    url: '/client/nav/theme/getConfig',
    method: 'get'
  })
}
